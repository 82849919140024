@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --active-color: #00AEEF;
    --font-lab-antiqua: "Lab Antiqua", georgia, serif;
    --font-calibre: "Calibre", arial, sans-serif;
    --font-sans: var(--font-calibre);
    --color-primary-1: #0A1E41;
    --color-footer-bg: var(--color-primary-1);
    --color-secondary-light: #F2EDE8;
    --color-secondary-light-1: #F3F6FA;
    --color-secondary-light-2: #FAF7F5;
    --page-content-width: 1440px;
}

.font-lab-antiqua {
    font-family: var(--font-lab-antiqua);
}

.text-color-primary {
    color: var(--color-primary-1);
}

.page-content {
    max-width: var(--page-content-width);
    @apply lg:px-14 md:px-8 px-6 lg:pt-14 md:pt-8 py-6 mx-auto;
}

.more-btn {
    @apply border border-black lg:px-5 lg:py-3 px-3 py-2 rounded lg:text-sm text-xs;
}

.btn-hover {
    @apply hover:bg-sky-400 hover:text-white hover:border-sky-400 transition-colors duration-200 ease-in-out;
}

.parent-option:hover .child {
    opacity: 1;
    color: var(--color-primary-1);
}


.a-hover-underline-list a,
.a-hover-underline {
    @apply hover:decoration-white decoration-transparent decoration-2 underline underline-offset-4 transition-all duration-300 ease-in-out;
}


@media (min-width:832px) {

    .content-text-max-w {
        max-width: 768px;
    }

}

.content-text-max-w {

    &>p>a,
    &>a {
        @apply underline decoration-sky-400 decoration-2 underline-offset-4 hover:decoration-slate-950;
    }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
    font-size: inherit;
    color: #fff;
    background-color: var(--color-primary-1);

    @apply rounded-full justify-center items-center size-10 opacity-100;

    &::after {
        top: 0.9rem;
        left: 0.85rem;
    }

}

.ant-carousel .slick-dots li button {
    opacity: 1;
    background-color: #999;
}

.ant-carousel .slick-active button {
    background-color: var(--color-primary-1) !important;
}

.GetInTouchForm-form-row-checkbox {

    :where(.css-dev-only-do-not-override-11lehqq).ant-checkbox-checked .ant-checkbox-inner {
        background-color: var(--color-primary-1) !important;
        border-color: var(--color-primary-1) !important;
    }

    :where(.css-dev-only-do-not-override-11lehqq).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
        border-color: var(--color-primary-1) !important;
    }
}

.Pagination {

    li {
        border-radius: 0% !important;
    }

    .ant-pagination-item-active {
        color: var(--color-primary-1);
        border-color: transparent;
        border-bottom: 3px solid var(--active-color);
    }
}


.search-input {

    :where(.css-dev-only-do-not-override-11lehqq).ant-input-affix-wrapper>input.ant-input {
        @apply text-xl;

        &::placeholder {
            @apply text-xl text-gray-950;
        }
    }

}