.SearchHeadDropdown {

    @apply relative;

    ul {
        scrollbar-width: thin;

        @apply absolute -z-10 opacity-0 -translate-y-4 transition-all duration-300 ease-in-out px-3 pb-3 max-h-80 overflow-y-auto;

        li:nth-child(1) {
            border: none;
        }

        li:hover {

            p {
                @apply underline underline-offset-4 decoration-slate-600 decoration-2;
            }

        }

        .check-item {
            p {
                @apply font-bold;
            }

            @media (min-width: 1024px) {
                .check-icon:before {
                    @apply bg-slate-500;
                }
            }

            @media (max-width: 1024px) {
                .check-icon-light:before {
                    @apply bg-slate-600;
                }

                .check-icon-dark:before {
                    @apply bg-white;
                }
            }
        }

        .check-icon {
            @apply relative;

            &:before {
                content: '';
                top: 10%;
                left: 10%;
                @apply absolute block size-4/5 bg-transparent;
            }

        }

    }

    &_active {

        ul {
            @apply opacity-100 translate-y-0 z-10;
        }

        @media (max-width: 1024px) {
            ul {
                @apply relative;
            }

        }

    }


}