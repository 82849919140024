.carousel-items {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    transition: all 0.4s ease-in-out;

    li:nth-child(1) {
        transition: all 0.4s ease-in-out;

        &>div {
            transition: opacity 0.4s ease-in-out;
        }

        &>p {
            transition: all 0.4s;
        }
    }

}

@media (max-width: 1023px) {
    li:nth-child(1) {
        scroll-snap-align: start;
        scroll-snap-stop: always;
    }

}


.ul-fold {
    @apply lg:ml-[40%] lg:w-auto 2xl:mr-48 xl:mr-24;

    li:nth-child(1) {
        @apply lg:w-2/5;
    }
}

.ul-expand {
    @apply lg:ml-32 lg:w-auto 2xl:mr-48 xl:mr-24;

    li:nth-child(1) {
        @apply lg:w-32;

        &>div {
            opacity: 0;
        }

        &>p {
            opacity: 1;
        }

    }

}


.carousel-item {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    @apply lg:w-[391px] w-full;

    .cover {
        transition: height 0.4s ease-in-out;
        @apply relative inline-block  md:h-[310px] h-56;

        &::after {
            content: '';
            height: 5px;
            transition: width 0.6s ease-in-out;
            @apply bg-sky-400 lg:w-0 w-full absolute bottom-0;
        }

    }

}


@media (min-width: 1024px) {
    .carousel-item {
        &:hover {

            .cover {
                // height: 310px;

                &::after {
                    @apply w-full;
                }
            }

            .description {
                height: 170px;
            }

        }
    }


    .description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        transition: height 0.4s ease-in-out;
    }
}



.btns {
    @apply lg:flex hidden top-10 2xl:right-56 xl:right-28 lg:right-8 gap-x-4 z-10;


    .icon {
        @apply text-xl bg-white rounded-full p-3 text-[#293766] border border-[#293766] cursor-pointer hover:bg-sky-400;
    }

    .icon-disabled {
        opacity: 0.5;
        @apply hover:bg-slate-800 cursor-not-allowed;
    }

}