:root {
    --full-width-bp: 992px;
    --scroll-padding: 40px;
}

.carousel-list {
    max-width: var(--full-width-bp);
}

@media (max-width: 1024px) {
    .carousel-list {
        padding: 0 var(--scroll-padding);
    }
}

@media (min-width: 832px) {
    :root {
        --scroll-padding: 64px;
    }

    .carousel-item {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

@media (min-width: 1024px) {
    :root {
        --scroll-padding: calc((100vw - var(--full-width-bp)) / 2);
    }

    .carousel-nav {
        height: calc(100% - 4rem);
    }
}

.carousel-items {
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    inset-inline-start: calc(-1* var(--scroll-padding));
    scroll-padding: 0 var(--scroll-padding);
}

.scroll-pad {
    width: var(--scroll-padding);
}

.carousel-item {
    max-width: var(--full-width-bp);
    width: calc(100% - var(--scroll-padding) * 2);
    scroll-snap-align: start;
    scroll-snap-stop: always;
    z-index: 11;

    @apply lg:mt-14 lg:mb-14 md:mt-8 md:mb-8 relative flex-none;


    figure {
        transition: transform .2s cubic-bezier(0.75, 0.02, 0.5, 1);
        aspect-ratio: 16 / 8;

        img {
            z-index: 2;
            margin-inline: auto;
        }

        figcaption {
            transition: opacity .25s cubic-bezier(0.75, 0.02, 0.5, 1);
        }
    }

}

.carousel-item-active {

    figure {
        transform: scale(1);

        img {
            @apply cursor-pointer hover:opacity-50 transition-opacity duration-200 ease-in-out;
        }

        img:hover+.figure-btn {
            opacity: 1;
        }

        .figure-btn {
            @apply absolute left-0 bottom-8 bg-sky-400 p-4 z-10 opacity-0 cursor-pointer transition-opacity duration-200 ease-in-out;
        }

    }

}

.carousel-nav {
    max-width: var(--full-width-bp);
}

.img-modal {
    background-color: #fff;
}

.img-modal>div {
    max-width: 100% !important;
    margin: 0 !important;
    padding-bottom: 0;
}

.img-modal-content {
    height: calc(100vh - 2.5rem);
    @apply mx-auto lg:w-10/12;
}