.swiper {
  padding: 20px 120px 120px;
}

.swiper-slide {
  transition: all 0.5s;
  padding: 0 50px;
  transform: scale(0.6);
  opacity: 0.7;
}

.swiper-slide-active {
  transform: scale(1.1) !important;
  opacity: 1;
  z-index: 2;
}

.swiper-slide:not(.swiper-slide-active) {
  /* 移除这里的样式 */
}

/* 左侧幻灯片 */
.swiper-slide-prev {
  /* 移除这里的样式 */
}

/* 右侧幻灯片 */
.swiper-slide-next {
  /* 移除这里的样式 */
}

/* 导航按钮容器 */
.navigation-buttons {
  position: absolute;
  bottom: 40px;
  left: 70%;
  transform: translateX(calc(40% + 20px));
  display: flex;
  gap: 10px;
  z-index: 10;
}

.swiper-button-prev,
.swiper-button-next {
  position: static !important;
  margin: 0 !important;
  /* background-color: rgba(255, 255, 255, 0.8); */
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 18px !important;
  color: #333;
}

/* 悬停效果 */
.swiper-button-prev:hover,
.swiper-button-next:hover {
  /* background-color: rgba(255, 255, 255, 1); */
  transform: scale(1.3);
} 