.carousel {
    padding-top: 88px;
    // background-color: var(--color-primary-1);
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    transition: opacity 1s ease-in-out;

    .content {
        max-width: 1440px;
        // background-color: rgba(255, 255, 255, 0.1);
        @apply mx-auto;
    }

    .nav-ul {
        @apply pb-10 flex justify-center mt-12;

        ul {
            @apply inline-flex gap-3 relative;
        }

        .tag {
            transition: left 0.5s;
            @apply size-4 rounded-full bg-sky-400 absolute z-10;
        }

        li {
            @apply size-4 border rounded-full relative z-10 cursor-pointer;
        }

    }

    .content-list {
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
        @apply w-full flex items-stretch overflow-y-hidden overflow-x-scroll;
    }

    .content-item {
        @apply w-full lg:px-14 px-8;
    }


    .content-title {
        font-family: var(--font-lab-antiqua);
        @apply lg:text-7xl text-6xl lg:mb-6 mb-4;
    }

    .content-media {
        @apply w-full relative overflow-hidden;

        img {
            @apply w-full h-full object-cover;
        }
    }

    .content-description {
        @apply lg:text-base text-sm text-neutral-200 mb-10;
    }

    .content-more-btn {
        @apply inline py-4 px-6 border border-white rounded-md text-sm;
    }

    .arrow-btn {
        @apply lg:flex hidden items-center justify-center size-12 text-xl border rounded-full cursor-pointer absolute z-40 bottom-10 left-14;
    }


    @media (min-width: 1024px) {
        .content-list {
            overflow: unset;
            @apply grid w-full relative;
        }

        .content-item {
            grid-area: 1 / -1;
            z-index: -1;
            @apply relative overflow-y-hidden;
        }

        .content-media {
            width: calc(100% + 56px);
            min-height: 475px;
            aspect-ratio: unset;
            grid-area: media;
            margin-top: 140px;
            @apply mb-0 h-full;
        }

        .content-item-div {
            grid-template-columns: calc(50% - 16px) calc(50% - 16px);
            gap: 0 32px;
            grid-template-rows: auto auto 1fr;
            grid-template-areas:
                "title media"
                "description media"
                "cta media";
            @apply w-full h-full grid items-start;
        }

        .nav-ul {
            @apply absolute right-20 top-0;
        }
    }

    @media (min-width: 1440px) {
        .content-item {
            overflow: initial;
        }

        .content-media {
            width: calc((100vw - 1328px) / 2 + 100%);
            height: 475px;
        }
    }

    @media (min-width:1300px) and (max-width: 1440px) {
        .content-item {
            @apply overflow-y-hidden;
        }

        .content-item-div {
            grid-template-columns: calc(40% - 24px) calc(60% - 24px);
            gap: 0 48px;
        }
    }



    @media (max-width: 1024px) {
        .content-item {
            scroll-snap-align: start;
            scroll-snap-stop: always;
            @apply w-screen flex-none relative pb-2;
        }

        .content-item-div {
            @apply w-full;
        }

        .content-media {
            margin-bottom: 32px;
            width: calc(100% + 32px);
            aspect-ratio: 16 / 9;
            overflow: hidden;
        }
    }


    .content-item-active {
        z-index: 1;
    }


    @media (min-width: 1024px) {

        .content-item {
            transition: all 1s ease-out;

            .content-title {
                opacity: 0;
                transform: translateY(10rem);
                transition: all 0.8s ease-in-out;
            }

            .content-description,
            .content-more-btn {
                opacity: 0;
                transition: opacity 1s ease-in-out;
            }

            .content-media img {
                transition: all 0.6s ease-in-out;
                opacity: 0.1;

            }

        }

        .content-item-active {
            z-index: 1;

            .content-title {
                opacity: 1;
                transform: translateY(0);
            }


            .content-description,
            .content-more-btn {
                opacity: 1;
            }

            .content-media img {
                opacity: 1;
            }

        }

        .item-in {
            .content-title {
                opacity: 0;
                transform: translateY(10rem);
            }

            .content-description,
            .content-more-btn {
                opacity: 0;
            }

        }


        .item-out {

            .content-title {
                opacity: 0;
                transform: translateY(-10rem);
            }

            .content-description,
            .content-more-btn {
                opacity: 0;
            }


            .content-media img {
                transform: scale(1.5);
                opacity: 0;
            }

        }

    }

    @media (max-width: 1023px) {
        .content-item {
            transition: all 1s ease-out;

            .content-title {
                opacity: 0;
                transform: translateY(10rem);
                transition: all 0.4s ease-in-out;
            }

            .content-description,
            .content-more-btn {
                opacity: 0;
                transition: opacity 1s ease-in-out;
            }

            .content-media img {
                transition: all 0.6s ease-in-out;
                opacity: 0.1;

            }

        }

        .content-item-active {
            z-index: 1;

            .content-title {
                opacity: 1;
                transform: translateY(0);
            }


            .content-description,
            .content-more-btn {
                opacity: 1;
            }

            .content-media img {
                opacity: 1;
            }

        }

        .item-in {
            .content-title {
                opacity: 0;
                transform: translateY(10rem);
            }

            .content-description,
            .content-more-btn {
                opacity: 0;
            }

        }

    }



}
