:root {
  --header-height: 80px;
  --drawer-bg: #f3f6fa;
}

.header {
  height: var(--header-height);
  transform: translateY(0);
  transition: transform 0.3s;
  z-index: 99;
}

.header-scroll {
  transform: translateY(-100%);
}

.anti-collapse {
  height: var(--header-height);
}
.nav-link{
  text-decoration: none;
}
.nav-item {
  text-decoration: underline solid rgba(0, 0, 0, 0);
  text-decoration-thickness: 3px;
  text-underline-offset: 8px;
  transition: text-decoration-color 0.25s cubic-bezier(0.75, 0.02, 0.5, 1),
    -webkit-text-decoration-color 0.25s cubic-bezier(0.75, 0.02, 0.5, 1);

  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-calibre);

  @apply lg:p-4 md:p-2 relative z-10;
  color: rgb(41, 55, 102);
}

.nav-item:after {
  content: "";
  @apply block w-full h-9 absolute left-0 top-9;
}

.nav-drawer {
  top: var(--header-height);
  left: 0;
  z-index: 1;
  max-height: calc(100vh - var(--header-height) - 50px);
  height: auto;
  overflow-y: auto;
  background-color: var(--drawer-bg);

  opacity: 0;
  @apply fixed flex w-full px-16 py-8 lg:py-10 md:py-10;
}

.nav-drawer-mask {
  z-index: 2;
  transition: all 0.5s ease-out;
  @apply fixed top-0 left-0 h-20 w-full bg-white;
}

@media (min-width: 1024px) {
  .nav-drawer {
    transition: transform 0.5s, opacity 0.5s 0.5s;
    transform: translateY(-101%);
  }

  .nav-drawer-mask:hover {
    background-color: white !important;
  }

  .nav-drawer-mask:hover ~ .nav-drawer {
    transform: translateY(-101%) !important;
  }

  .nav-drawer-mask:hover ~ .nav-item {
    text-decoration-color: transparent !important;
  }

  .nav-li:hover .nav-item,
  .nav-item:hover {
    text-decoration-color: var(--active-color);
  }

  .nav-li:hover .nav-drawer {
    transform: translateY(0);
    opacity: 1;
  }

  .nav-li:hover .nav-drawer-mask,
  .nav-item:hover + .nav-drawer-mask {
    background-color: var(--drawer-bg);
    z-index: 3;
  }
}

.search-btn::after {
  content: "";
  @apply block lg:w-px h-8 absolute left-0 top-3 bg-gray-400;
}

.nav-drawer-content {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(51, 61, 77, 0.1) rgba(51, 61, 77, 0.05);
  @apply flex flex-wrap w-full xl:max-w-screen-2xl mx-auto h-full;
}

.nav-drawer-content-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto 1fr;
  gap: 0 56px;
  align-items: start;
}

.nav-drawer-content-left {
  &_title {
    font-family: var(--font-lab-antiqua);
  }

  .a-btn {
    font-family: var(--font-sans);
  }
}

.nav-drawer-content-right {
  @apply flex flex-wrap gap-16;

  &_group {
    flex: 1;
    @apply lg:max-w-64;
  }

  &_title {
    @apply text-left text-xl leading-5;
  }

  &_link-ul {
    @apply mt-5;

    li {
      @apply flex justify-between items-center w-full border-b border-gray-300 hover:font-semibold;
    }

    li a {
      font-size: 13px;
      @apply block py-4 flex-1;
    }
  }
}

// md
@media (max-width: 1023px) {
  .header-md {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: white !important;
  }

  .header-md .head-nav button {
    margin-top: 5px;
  }

  .header-md > div {
    align-items: flex-start;
    padding-top: 8px;
  }

  .header-md-open .head-nav > ul {
    height: calc(100vh - var(--header-height)) !important;

    li {
      opacity: 1 !important;
    }
  }

  .header-md .head-nav > ul {
    transition: height 0.5s;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    right: 0;
    top: var(--header-height);
    margin: 0;
    overflow-y: auto;
    height: 0;
    transition: all 0.3s;
  }

  .nav-drawer-mask:hover,
  .head-active-bg,
  .head-active-bg .nav-drawer-mask {
    background-color: var(--drawer-bg) !important;
  }

  .header-md .head-nav > ul > li {
    transition: opacity 0.5s 0.5s;
    @apply px-5 w-full opacity-0;

    .nav-item {
      font-size: 16px;
      @apply w-full inline-block pt-5;
    }

    .nav-drawer {
      position: relative;
      top: 20px;
      transform: translateY(0);
      transition: opacity 0.2s 0.2s;
      transition: height 0.5s;
      max-height: 100%;
      height: 0;
      padding: 0;

      .nav-drawer-content {
        justify-content: flex-end;
      }

      .nav-drawer-content-grid {
        @apply flex flex-col flex-wrap gap-10 md:w-10/12 w-11/12;
      }

      .nav-drawer-content-left {
        @apply w-full;
      }

      .nav-drawer-content-right {
        @apply w-full flex-col gap-10;

        &_group ul {
          gap: 0 20px;
          @apply flex flex-wrap;

          li {
            width: calc(50% - 10px);
          }
        }
      }
    }
  }

  .nav-li-open {
    padding-bottom: 2rem;

    .nav-item {
      text-decoration-color: var(--active-color);
    }

    .nav-drawer {
      opacity: 1;
      height: 100% !important;
    }
  }
}

// sm
@media (max-width: 768px) {
  .nav-drawer-content-right {
    &_link-ul {
      @apply flex-col;

      li {
        width: 100% !important;
      }
    }
  }
}
