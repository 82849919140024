@media (min-width: 1024px) {

    .GetInTouchForm {
        max-width: var(--page-content-width);
    }

    .GetInTouchForm-header {
        background-color: white;
    }

}

@media (max-width: 1023px) {
    .GetInTouchForm {
        background-color: var(--color-secondary-light-2);
    }
}


@media (min-width: 1440px) {

    .GetInTouchForm-form-row {
        @apply flex gap-x-10;
    }

}

@media (max-width: 1024px) and (min-width: 680px) {
    .GetInTouchForm-form-row {
        @apply flex gap-x-10;
    }
}

.GetInTouchForm-body {
    background-color: var(--color-secondary-light-2);
}
