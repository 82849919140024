.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 每行两列 */
  gap: 20px; /* 方块之间的间距 */
}

.member {
  display: flex; /* 让链接内部使用 Flexbox 布局 */
  align-items: center; /* 垂直居中 */
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-decoration: none; /* 去除链接的默认下划线 */
  color: inherit; /* 继承颜色 */
  transition: transform 0.2s, box-shadow 0.2s; /* 添加动画效果 */
}

.member:hover {
  transform: scale(1.02); /* 鼠标悬停时放大效果 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* 增加阴影 */
}

.member-content {
  display: flex; /* 让图片和文字水平排列 */
  align-items: center; /* 垂直居中对齐 */
}

.member img {
  width: 150px; /* 图标宽度 */
  height: 150px; /* 图标高度 */
  object-fit: contain; /* 保持图片比例 */
  margin-right: 20px; /* 图片与文字之间的间距 */
}

.member-right-text {
  font-size: 16px;
  font-weight: 500;
}
