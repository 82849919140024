@media (min-width: 1024px) {

    .contact-card-two,
    .contact-card {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        grid-template-areas:
            "image"
            "header";
    }

    .contact-card-description {
        grid-area: image;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .contact-card-md {
        position: relative;
        grid-template-columns: 55% 45%;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "header image"
            "description image";
        @apply pt-12;
    }

    .contact-card-two {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        grid-template-areas:
            "image"
            "header";
    }

    .contact-card-description-md {
        grid-area: description;
    }

    .contact-card-description-sm {
        grid-area: image;
    }
}

@media (max-width: 768px) {

    .contact-card-two,
    .contact-card {
        position: relative;
        grid-template-columns: auto;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "image"
            "header";
        margin-bottom: 2rem;
    }

}

.contact-card-title {
    grid-area: header;
}

.contact-card-image {
    grid-area: image;
}

.contact-card-description:hover,
.contact-card-image:hover+.contact-card-description {
    @apply opacity-100 z-10;
}

.contact-card-description-md {
    @apply lg:bg-neutral-100;
}

.contact-card-description-sm {
    @apply md:opacity-0 md:-z-10 md:mt-8 md:ml-8 md:p-8 md:bg-slate-900 md:text-white;
}

.contact-card-description {
    @apply relative lg:opacity-0 lg:-z-10 lg:mt-8 lg:ml-8 lg:p-8 lg:mb-3 flex flex-col justify-between items-stretch w-auto py-4 transition-all duration-500 ease-in-out;

    li {

        @apply text-sm;

        a {
            @apply flex;
        }

    }

}