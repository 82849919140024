@media (min-widht:1024px) {
    .card-in-img {
        min-height: 480px;
    }
}

@media (min-widht:1024px) {
    .card-in-img {
        min-height: 480px;
    }
}


.card-in-img {
    min-height: 200px;
}

.card-in-img-content {
    max-width: 490px;
    height: 90%;
    background-color: #FAF7F5;
    color: #0A1E41;
}